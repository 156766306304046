import { useEffect, useState } from "react";
import { usePopupQuery } from "@hooks/query/popup.query";
import { BUILDER_NOTICE_POPUP_KEY } from "./NoticePopup";
import { NoticePopupManager } from "./NoticePopupManager";
import { BUILDER_RELEASE_POPUP_KEY, ReleasePopup } from "./ReleasePopup";
import { getCookie } from "@maven-rest/states";

export const Popup = () => {
  const { data: popupInfo, isSuccess } = usePopupQuery();
  const [isReleaseNoteOpen, setIsReleaseNoteOpen] = useState<boolean>(false);
  const [allNoticesClosed, setAllNoticesClosed] = useState<boolean>(false);

  const releaseCookie: string = getCookie(BUILDER_RELEASE_POPUP_KEY);

  useEffect(() => {
    if (!popupInfo) return;
    const hasValidNotice = (popupInfo.notices ?? []).findIndex((notice) => !getCookie(`${BUILDER_NOTICE_POPUP_KEY}:${notice.id}`)) > -1;
    if (!hasValidNotice) {
      setAllNoticesClosed(true);
    }
  }, [popupInfo]);

  useEffect(() => {
    if (!isSuccess) return;

    if (!!popupInfo.release && releaseCookie !== popupInfo.release.version && allNoticesClosed) {
      setIsReleaseNoteOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, popupInfo, allNoticesClosed]);

  return (
    <>
      <NoticePopupManager
        notices={popupInfo?.notices}
        release={popupInfo?.release}
        onAllNoticesClosed={() => {
          setAllNoticesClosed(true);
        }}
      />
      {popupInfo?.release && (
        <ReleasePopup
          open={isReleaseNoteOpen}
          release={popupInfo.release}
          onClose={() => {
            setIsReleaseNoteOpen(false);
          }}
        />
      )}
    </>
  );
};
